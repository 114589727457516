import './styles/site.css'
import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { Members } from './components/Members';
import { Ratings } from './components/Ratings';
import { NewRating } from './components/NewRating';
import { AddOrUpdateReviews } from './components/AddOrUpdateReviews';

export default class App extends Component {
  static displayName = App.name;

  render () {
    return (
      <Layout>
        <Route exact path='/' component={Home} />
        <Route exact path='/ratings' component={Ratings} />
        <Route exact path='/ratings/new' component={NewRating} />
        <Route exact path='/ratings/:ratingId(\d+)' component={NewRating} />
        <Route exact path='/reviews/:ratingId(\d+)' component={AddOrUpdateReviews} />
        <Route exact path='/members' component={Members} />
      </Layout>
    );
  }
}
