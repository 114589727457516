import '../styles/home.css';
import React, { Component } from 'react';

export class Home extends Component {
    static displayName = Home.name;

    state = {
        pageHeader: "Welcome to D&D Beer Club",
        activeMembers: [],
        currentBrewmasterId: 0
    }

    componentDidMount() {
        fetch('/api/members')
            .then(res => res.json())
            .then(res => res.filter(m => m.isActive))
            .then(m => this.setState({ activeMembers: m }));
        fetch('/api/configoptions')
            .then(res => res.json())
            .then(res => res.find(opts => opts.option === "CurrentToastmasterMemberId"))
            .then(opt => this.setState({ currentBrewmasterId: opt.numVal }));
    }

  render () {
    return (
      <div className="home-container">
        <h2>{this.state.pageHeader}</h2>
            <div className="home-paragraph">
                Consider yourself lucky to have found your way to the very refined, very exclusive Gentleman's Beer Club. Established in 2014, the GBC was created to uncover the most interesting (and often questionable) beers the world has to offer.
            </div>
            <div className="home-paragraph">
                This esteemed organization maintains a proper order as to whom shall select the beverage for consideration. This order is as follows, indicating the member whose turn it is to provide the brews:
                <ul className="home-schedule-list">
                    {this.state.activeMembers.map(m =>
                        <li key={`member_${m.memberId}`} className={this.state.currentBrewmasterId === m.memberId ? "home-schedule-list-item-current" : "home-schedule-list-item"}>
                            {m.aka}
                        </li>
                    )}
                </ul>
            </div>
      </div>
    );
  }
}
