import React, { Component } from 'react';
import { Media } from 'reactstrap';
import '../styles/ratings.css';

export class Ratings extends Component {

    state = {
        pageHeader: "Ratings",
        ratings: [],
        filteredRatings: []
    }

    constructor(props) {
        super(props);

        this.onRatingsSearchChange = this.onRatingsSearchChange.bind(this);
    }

    componentDidMount() {
        fetch('/api/ratings/')
            .then(res => res.json())
            .then(res => this.setState({ ratings: res, filteredRatings: res }));
    }

    onRatingsSearchChange(e) {
        let filteredList = [];

        if (e.target.value !== '') {
            filteredList = this.state.ratings.filter(r => {
                // Look for searched text in brew name, company, and reviews (3/12/2020 JBrown)
                //this.highlight(e.target.value);
                return r.brewName.toString().toLowerCase().includes(e.target.value.toLowerCase())
                    || r.brewCompany.toString().toLowerCase().includes(e.target.value.toLowerCase())
                    || r.reviews.filter(rev => { return rev.ratingId === r.ratingId && rev.comment && rev.comment.toString().toLowerCase().includes(e.target.value.toLowerCase()); }).length;
            });
        } else {
            filteredList = this.state.ratings;
        }

        this.setState({ filteredRatings: filteredList });
    }

    highlight(text) {
        var inputText = document.getElementById("ratings");
        var innerHTML = inputText.innerHTML;
        var index = innerHTML.indexOf(text);
        if (index >= 0) {
            innerHTML = innerHTML.substring(0, index) + "<span class='highlight'>" + innerHTML.substring(index, index + text.length) + "</span>" + innerHTML.substring(index + text.length);
            inputText.innerHTML = innerHTML;
        }
    }

    render() {
        return (
            <div className="rating-container">
                <h2>{this.state.pageHeader}</h2>
                <div className="ratings-paragraph">
                    <p>Contained herein are the annals of the judgements put forth by the revered members of the Gentleman's Beer Club. Every appraisal put forth adheres to the sacred oath sworn by each member. Each vote is made with arrant honesty, and no vote is swayed by another member.</p>
                    <p>The wise man uses these rankings to guide his choice to a beverage that is truly pleasant to his palate.</p>
                    <p>The fool ignores this roadmap to gentlemanly indulgence, and carves out for himself a path of utter destruction.</p>
                    <p>Let these words serve as a testament to the candor and elegance of the esteemed members of the Gentleman's Beer Club! Hear, hear!</p>
                </div>
                <div className="ratings-search">
                    <label htmlFor="ratingsSearch">Search Ratings &amp; Memes</label>
                    <input id="ratingsSearch" type="text" onChange={this.onRatingsSearchChange} />
                </div>
                <div id="ratings">
                    {this.state.filteredRatings.sort((r1, r2) => r2.reviewDate > r1.reviewDate ? 1 : 0).map(r =>
                        <div key={`rating_${r.ratingId}`}>
                            <Media className="rating-media-container">
                                <Media className="rating-media-left" left>
                                    <Media className="rating-media-header">{r.brewName}</Media>
                                    <Media className="rating-media-subheader">{r.brewCompany}</Media>
                                    {/*<Media className="rating-media-image" object src={require('../images/' + (r.imgUri !== '' ? r.imgUri : 'beer-solid.svg'))} alt={`${r.brewName} (${r.brewCompany})`} />*/}
                                    {
                                        r.imgUri && r.imgUri !== '' ?
                                           <Media className="rating-media-image" object src={require('../images/' + (r.imgUri !== '' ? r.imgUri : 'beer-solid.svg'))} alt={`${r.brewName} (${r.brewCompany})`} />
                                           : <Media style={{ display: 'block', fontSize: '96px', textAlign: 'center', verticalAlign: 'middle', width: '100%' }}>🍺</Media>}
                                    <Media middle>Overall: {r.averageScore}🍆</Media>
                                </Media>
                                <Media className="rating-media-body" body>
                                    {r.reviews.map(rev =>
                                        <Media className="review-media-container" key={rev.id}>
                                            <Media className="review-media-list" list>
                                                <Media className="review-media-header">{rev.score}🍆 {rev.member.aka}</Media>
                                                <Media className="review-media-content" dangerouslySetInnerHTML={{__html: rev.comment}}></Media>
                                            </Media>
                                        </Media>
                                    )}
                                </Media>
                            </Media>
                            <div className="rating-container-divider"></div>
                        </div>
                    )}
                </div>
            </div>
        )
    }
}