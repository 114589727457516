import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Button from 'reactstrap/lib/Button';
import Form from 'reactstrap/lib/Form';
import FormGroup from 'reactstrap/lib/FormGroup';
import FormText from 'reactstrap/lib/FormText';
import Input from 'reactstrap/lib/Input';
import Label from 'reactstrap/lib/Label';

const NewRating = props => {
    const [newRating, setNewRating] = useState({
        id: undefined,
        ratingId: undefined,
        memberId: 0,
        reviewDate: "",
        brewName: "",
        brewCompany: "",
        abv: 0,
        imgUri: "",
        averageScore: 0,
        comment: "",
        reviews: undefined
    });

    const { ratingId } = useParams();
    const history = useHistory();
    const [members, setMembers] = useState([]);
    const [showId, setShowId] = useState(false);
    const [isEdit, setIsEdit] = useState(false);

    useEffect(() => {
        if (ratingId) {
            setIsEdit(true);
            fetch(`/api/ratings/${ratingId}`)
                .then(res => res.json())
                .then(res => setNewRating(res));
        }
        else {
            fetch('/api/ratings/nextAvailableRatingId')
                .then(res => res.json())
                .then(res => setNewRating({...newRating, ratingId: res, reviewDate: new Date().toJSON().slice(0,10)}));
        }
        
        fetch('/api/members')
            .then(res => res.json())
            .then(res => res.sort((member1, member2) => member1.aka > member2.aka ? 1 : -1))
            .then(res => setMembers(res));
    }, []);

    const postRating = e => {
        e.preventDefault();
        console.log(newRating);

        let postUrl = `api/ratings/`;
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(newRating)
        };

        if (isEdit) {
            postUrl += `${newRating.id}`;
            requestOptions.method = 'PUT';
        }

        fetch(postUrl, requestOptions)
            .then(() => { history.push(newRating.ratingId) });
    };

    return (
        <div>
            <h3>{ isEdit ? "Edit Rating" : "New Rating" }</h3>
            <div className="new-rating-form">
            <Form onSubmit={(e) => { postRating(e) }}>
                <FormGroup hidden>
                    <Label for="id">ID</Label>
                    <Input type="text" name="id" id="id" value={newRating.id || ""} readOnly />
                </FormGroup>
                <FormGroup>
                    <Label for="ratingId">Rating ID</Label>
                    <Input type="text" name="ratingId" id="ratingId" value={newRating.ratingId || 0} readOnly />
                </FormGroup>
                <FormGroup>
                    <Label for="brewName">Brew Name</Label>
                    <Input type="text" name="brewName" id="brewName" value={newRating.brewName} onChange={(e) => {setNewRating({...newRating, brewName: e.target.value})}} />
                </FormGroup>
                <FormGroup>
                    <Label for="brewCompany">Brew Company</Label>
                    <Input type="text" name="brewCompany" id="brewCompany" value={newRating.brewCompany} onChange={(e) => {setNewRating({...newRating, brewCompany: e.target.value})}} />
                </FormGroup>
                <FormGroup>
                    <Label for="reviewDate">Review Date</Label>
                    <Input type="datetime" name="reviewDate" id="reviewDate" value={newRating.reviewDate} onChange={(e) => {setNewRating({...newRating, reviewDate: e.target.value})}} />
                </FormGroup>
                <FormGroup>
                    <Label for="averageScore">Average Rating</Label>
                    <Input type="number" step={0.25} name="averageScore" id="averageScore" value={newRating.averageScore} onChange={(e) => {setNewRating({...newRating, averageScore: parseFloat(e.target.value)})}} />
                </FormGroup>
                <FormGroup>
                    <Label for="memberId">Toastmaster</Label>
                    <Input type="select" name="member" id="memberId" value={newRating.memberId} onChange={(e) => {setNewRating({...newRating, memberId: parseInt(e.target.value)})}} >
                        <option value={0}>-- Select Member --</option>
                        {members.map(m => 
                                <option key={`member_${m.memberId}`} value={m.memberId}>{m.aka}</option>
                        )}
                    </Input>
                </FormGroup>
                <FormGroup>
                    <Label for="abv">ABV</Label>
                    <Input type="number" step={0.01} name="abv" id="abv" value={newRating.abv} onChange={(e) => {setNewRating({...newRating, abv: parseFloat(e.target.value)})}} />
                </FormGroup>
                <FormGroup>
                    <Label for="brewImage">Brew Image</Label>
                    <Input type="file" name="brewImage" id="brewImage" value={newRating.imgUri || ""} onChange={(e) => {setNewRating({...newRating, imgUri: e.target.value})}} />
                    <FormText color="muted">
                    Not implemented yet...
                    </FormText>
                </FormGroup>
                <FormGroup>
                    <Label for="comment">Overall Comment</Label>
                    <Input type="textarea" name="comment" id="comment" value={newRating.comment || ""} onChange={(e) => {setNewRating({...newRating, comment: e.target.value})}} />
                </FormGroup>
                <Button>Submit</Button>
                </Form>
            </div>
        </div>
    );
};

export { NewRating };