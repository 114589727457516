import '../styles/members.css';
import React, { Component } from 'react';

export class Members extends Component {
    constructor(props) {
        super(props);
    }

    state = {
        pageHeader: "Active Members",
        members: []
    }

    componentDidMount() {
        fetch('/api/members/active')
            .then(res => res.json())
            .then(res => this.setState({ members: res.sort((a, b) => a.firstName > b.firstName ? 1 : 0) }));
    }

    render() {
        return (
            <div className="members-container">
                <h2>{this.state.pageHeader}</h2>
                <div className="members-list-container">
                    <ul className="members-list">
                        {this.state.members.filter(m => m.isActive).map(m =>
                            <li key={m.memberId}>{m.firstName} {m.lastName} {m.aka !== null && m.aka !== "" && m.aka !== m.firstName ? `("${m.aka}")` : ''}</li>
                        )}
                    </ul>
                </div>
            </div>
        )
    }
}