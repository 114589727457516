import '../styles/sideNav.css';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export class SideNav extends Component {
    constructor(props) {
        super(props);

        this.state = {
            navLogoText: "D&D Beer Club",
            homeNavText: "Home",
            ratingsNavText: "Ratings",
            membersNavText: "Members",
            selectedTheme: "dark"
        }

        this.toggleTheme = this.toggleTheme.bind(this);
    }

    componentDidMount() {

    }

    toggleTheme() {
        if (this.state.selectedTheme === "dark") {
            document.documentElement.style.setProperty('--sitePalette1', '#ffffff');
            document.documentElement.style.setProperty('--sitePalette2', '#f0f5f9');
            document.documentElement.style.setProperty('--sitePalette3', '#c9d6df');
            document.documentElement.style.setProperty('--sitePalette4', '#52616b');
            document.documentElement.style.setProperty('--sitePalette5', '#1e2022');

            this.setState({ selectedTheme: "light" });
        }
        else {
            document.documentElement.style.setProperty('--sitePalette5', '#ffffff');
            document.documentElement.style.setProperty('--sitePalette4', '#f0f5f9');
            document.documentElement.style.setProperty('--sitePalette3', '#c9d6df');
            document.documentElement.style.setProperty('--sitePalette2', '#52616b');
            document.documentElement.style.setProperty('--sitePalette1', '#1e2022');

            this.setState({ selectedTheme: "dark" });
        }
    }

    render() {
        return (
            <nav className="sidenav">
                <ul className="sidenav-nav">
                    <li className="logo">
                        <Link className="sidenav-link" to="/">
                            <span className="link-text logo-text">{this.state.navLogoText}</span>
                            <svg
                                data-icon="angle-double-right"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512"
                            >
                                <g className="fa-group">
                                    <path
                                        fill="currentColor"
                                        d="M224 273L88.37 409a23.78 23.78 0 0 1-33.8 0L32 386.36a23.94 23.94 0 0 1 0-33.89l96.13-96.37L32 159.73a23.94 23.94 0 0 1 0-33.89l22.44-22.79a23.78 23.78 0 0 1 33.8 0L223.88 239a23.94 23.94 0 0 1 .1 34z"
                                        className="fa-secondary"
                                    ></path>
                                    <path
                                        fill="currentColor"
                                        d="M415.89 273L280.34 409a23.77 23.77 0 0 1-33.79 0L224 386.26a23.94 23.94 0 0 1 0-33.89L320.11 256l-96-96.47a23.94 23.94 0 0 1 0-33.89l22.52-22.59a23.77 23.77 0 0 1 33.79 0L416 239a24 24 0 0 1-.11 34z"
                                        className="fa-primary"
                                    ></path>
                                </g>
                            </svg>
                        </Link>
                    </li>

                    <li className="nav-item">
                        <Link className="sidenav-link" to="/">
                            <svg
                                data-icon="alien-monster"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 576 512"
                            >
                                <g className="fa-group">
                                <path fill="currentColor" d="M368 96h-48V56a24 24 0 0 0-24-24H24A24 24 0 0 0 0 56v400a24 24 0 0 0 24 24h272a24 24 0 0 0 24-24v-42.11l80.61-36a80.08 80.08 0 0 0 47.39-73V176a80.09 80.09 0 0 0-80-80zM128 368a16 16 0 0 1-32 0V144a16 16 0 0 1 32 0zm96 0a16 16 0 0 1-32 0V144a16 16 0 0 1 32 0zm160-63.14a16 16 0 0 1-9.48 14.61L320 343.8V160h48a16 16 0 0 1 16 16z" className="fa-secondary"></path>
                                <path fill="currentColor" d="M208 128a16 16 0 0 0-16 16v224a16 16 0 0 0 32 0V144a16 16 0 0 0-16-16zm-96 0a16 16 0 0 0-16 16v224a16 16 0 0 0 32 0V144a16 16 0 0 0-16-16z" className="fa-primary"></path>
                                </g>
                            </svg>
                            <span className="link-text">{this.state.homeNavText}</span>
                        </Link>
                    </li>

                    <li className="nav-item">
                        <Link className="sidenav-link" to="/ratings">
                            <svg
                                data-icon="alien-monster"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 576 512"
                            >
                                <g className="fa-group">
                                    <path fill="currentColor" d="M163.56 229.81c39.61-39.15 56.65-80.59 89.12-113.11 14.8-14.84 20.18-37.24 25.39-58.91C282.52 39.29 291.82 0 312 0c24 0 72 8 72 81.45 0 42.42-26 66.21-33.28 94.55h101.73c33.39 0 59.39 27.75 59.55 58.1.08 17.94-7.55 37.25-19.44 49.19l-.11.12c9.84 23.33 8.24 56-9.31 79.46 8.68 25.9-.07 57.71-16.38 74.76 4.3 17.6 2.24 32.58-6.15 44.63C440.2 511.59 389.62 512 346.84 512H344c-48.28 0-87.8-17.6-119.56-31.73-16-7.09-36.82-15.88-52.65-16.17a12 12 0 0 1-11.78-12V238.33a12 12 0 0 1 3.55-8.52z" className="fa-secondary"></path>
                                    <path fill="currentColor" d="M104 224H24a24 24 0 0 0-24 24v240a24 24 0 0 0 24 24h80a24 24 0 0 0 24-24V248a24 24 0 0 0-24-24zM64 472a24 24 0 1 1 24-24 24 24 0 0 1-24 24z" className="fa-primary"></path>
                                </g>
                            </svg>
                            <span className="link-text">{this.state.ratingsNavText}</span>
                        </Link>
                    </li>

                    <li className="nav-item">
                        <Link className="sidenav-link" to="/members">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 642 512"
                            >
                                <g className="fa-group">
                                    <path fill="currentColor" d="M96 224a64 64 0 1 0-64-64 64.06 64.06 0 0 0 64 64zm480 32h-64a63.81 63.81 0 0 0-45.1 18.6A146.27 146.27 0 0 1 542 384h66a32 32 0 0 0 32-32v-32a64.06 64.06 0 0 0-64-64zm-512 0a64.06 64.06 0 0 0-64 64v32a32 32 0 0 0 32 32h65.9a146.64 146.64 0 0 1 75.2-109.4A63.81 63.81 0 0 0 128 256zm480-32a64 64 0 1 0-64-64 64.06 64.06 0 0 0 64 64z" className="fa-secondary"></path>
                                    <path fill="currentColor" d="M396.8 288h-8.3a157.53 157.53 0 0 1-68.5 16c-24.6 0-47.6-6-68.5-16h-8.3A115.23 115.23 0 0 0 128 403.2V432a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48v-28.8A115.23 115.23 0 0 0 396.8 288zM320 256a112 112 0 1 0-112-112 111.94 111.94 0 0 0 112 112z" className="fa-primary"></path>
                                </g>
                            </svg>
                            <span className="link-text">{this.state.membersNavText}</span>
                        </Link>
                    </li>



                    <li className="nav-item">
                        <Link className="sidenav-link" onClick={this.toggleTheme} to="#">
                            <svg
                                className="theme-icon"
                                id="lightIcon"
                                data-icon="moon-stars"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                            >
                                <g className="fa-group">
                                    <path
                                        fill="currentColor"
                                        d="M320 32L304 0l-16 32-32 16 32 16 16 32 16-32 32-16zm138.7 149.3L432 128l-26.7 53.3L352 208l53.3 26.7L432 288l26.7-53.3L512 208z"
                                        className="fa-secondary"
                                    ></path>
                                    <path
                                        fill="currentColor"
                                        d="M332.2 426.4c8.1-1.6 13.9 8 8.6 14.5a191.18 191.18 0 0 1-149 71.1C85.8 512 0 426 0 320c0-120 108.7-210.6 227-188.8 8.2 1.6 10.1 12.6 2.8 16.7a150.3 150.3 0 0 0-76.1 130.8c0 94 85.4 165.4 178.5 147.7z"
                                        className="fa-primary"
                                    ></path>
                                </g>
                            </svg>
                    <span className="link-text">Theme</span>
                        </Link>
                    </li>
                </ul>
            </nav>
        );
    }
}