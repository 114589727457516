import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';

export const AddOrUpdateReviews = props => {
    const [newReviews, setNewReviews] = useState();
    const [isEdit, setIsEdit] = useState();
    const { ratingId } = useParams();

    // {
    //     id: undefined,
    //     reviewId: undefined,
    //     ratingId: undefined,
    //     memberId: ,
    //     score: 1.5,
    //     comment: null,
    //     member: null
    // }

    useEffect(() => {
        if (ratingId) {
            setIsEdit(true);
            fetch(`/api/reviews/${ratingId}`)
                .then(res => res.json())
                .then(res => setNewReviews(res));
        }
    }, []);

    return (
        <div className="reviews-container">
            <h3>{ isEdit ? "Edit Reviews" : "Add Reviews" }</h3>
            <div className="reviews">
                {newReviews && newReviews.map(r => 
                    <div key={`review_${r.reviewId}`}>
                        Review: {r.reviewId}
                    </div>
                )}
            </div>
        </div>
    );
};